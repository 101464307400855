html,
body,
#root
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ivory;
}

canvas
{
    touch-action: none;
}

.htmlScreen iframe
{
    width: 780px;
    height: 540px;
    border: none;
    border-radius: 20px;
    background: #000000;
}